import axios from "axios";
import Swal from "sweetalert2";
import encryptData from "./utils/encryption";
import decryptData from "./utils/decryption";

const encryption = process.env.NEXT_PUBLIC_ENCRYPTION || "FALSE";
const baseURL = process.env.NEXT_PUBLIC_APIPOINT;

const caxios = axios.create({
  baseURL,
});

caxios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;

    if (encryption === "TRUE") {
      if (config.method === "put" || config.method === "PUT") {
        config.data = { data: config.data };
      } 
      else {
        config.data = { data: encryptData(config.data) };
      }
    }
    //  else {
    //   config.data = { data: config.data };
    // }

    return config;
  },
  (error) => {
    // Handle request errors here, if needed
    return Promise.reject(error);
  }
);

// Add a response interceptor
caxios.interceptors.response.use(
  (response) => {
    if (encryption === "TRUE") {
      response.data.encoded = JSON.parse(decryptData(response.data.encoded));
    } else {
      // eslint-disable-next-line no-self-assign
      response.data.encoded = response.data.encoded;
    }

    return response.data.encoded;
  },
  (error) => {
    error.response.data.encoded =
      encryption === "TRUE"
        ? JSON.parse(decryptData(error.response.data.encoded))
        : error.response.data.encoded;

    //! YOU CAN DIRECTLY HANDLE ERRORS HERE!!!
    // handleCommonErrors(error.response.data.encoded);
   
    // console.log("axios error", error.response.data.encoded.errMsg)
    // const navigate = useNavigate();
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: `${error.response.data.encoded.errMsg}`,
      // footer: '<a href="">Why do I have this issue?</a>'
    }).then((res)=>{
      if(res.isConfirmed){
        // window.location.reload()
        // navigate("/")
      }
    })

    return error.response.data.encoded;
  }
);

export default caxios;
